import { styled } from "@mui/system";
import { PALETTE_COLORS } from "../theme/ColorPalettes";

const ProActiveIcon = (): JSX.Element => (
  <StyledSvg
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Logo-&amp;-symbol"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <StyledSvgGroup>
        <path
          d="M24,0 C37.254834,0 48,10.745166 48,24 C48,37.254834 37.254834,48 24,48 C10.745166,48 0,37.254834 0,24 C0,
             10.745166 10.745166,0 24,0 Z M24,8 C15.163444,8 8,15.163444 8,24 C8,32.836556 15.163444,40 24,40 C32.836556,
             40 40,32.836556 40,24 C40,15.163444 32.836556,8 24,8 Z"
          id="Combined-Shape"
        />
        <circle cx="24" cy="24" r="5" />
      </StyledSvgGroup>
    </g>
  </StyledSvg>
);

const StyledSvg = styled("svg")({
  maxWidth: "29px",
  maxHeight: "29px",
  padding: "10px 15px 10px 0px",
});

const StyledSvgGroup = styled("g")({
  fill: PALETTE_COLORS.proActive,
});

export default ProActiveIcon;
