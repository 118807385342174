export const PALETTE_COLORS = {
  // Colors from sketch product theme
  primary: "#037580",
  primaryVariant: "#7ACBE2",
  secondary: "#0E2343",
  background: "#F7F7F7",
  white: "#FFFFFF",
  fontBlack: "#000000",
  darkGrey: "#a9a8a9",
  mediumGrey: "#D9D9D6",
  lightGrey: "#EAEAEB",
  red: "#FF756B",
  peach: "#F0A693",
  yellow: "#FCE181",
  green: "#94CCAC",
  success: "#05967A",
  warning: "#fe8b14",
  error: "#b32915",
  information: "#143361",
  proActive: "#026670",
};
