import { Box, Typography, styled } from "@mui/material";
import NetCleanLogo from "./NetCleanLogo";

const StyledFooterText = styled(Typography)({
  fontSize: "12px",
  fontWeight: "bold",
  color: "#a2a3a8",
  padding: "10px",
});

const StyledBox = styled(Box)({
  position: "fixed",
  zIndex: 2,
  bottom: 0,
  right: 0,
  textAlign: "right",
});

function CopyrightFooter() {
  return (
    <StyledBox>
      <NetCleanLogo></NetCleanLogo>
      <StyledFooterText>
        ProActive Demo Images ©2003 - 2023 NetClean Technologies
      </StyledFooterText>
    </StyledBox>
  );
}

export default CopyrightFooter;
