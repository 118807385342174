import ImageCard from "./ImageCard";
import { Box, styled } from "@mui/material";

const StyledRootContainer = styled(Box)({
  alignContent: "center",
  justifyContent: "center",
  display: "flex",
  flexFlow: "row",
  width: "100%",
  marginBottom: "20px",
});

const Cards = () => (
  <StyledRootContainer>
    <ImageCard
      title="Image 1"
      text="This image will generate an incident after the agent has updated its signature database"
      url="/resources/images/test-images/image1.jpg"
    />
    <ImageCard
      title="Image 2"
      text="This image will generate an incident after the agent has updated its signature database"
      url="/resources/images/test-images/image2.jpg"
    />
    <ImageCard
      title="Image 3"
      text="This image will generate an incident after the agent has updated its signature database"
      url="/resources/images/test-images/image3.jpg"
    />
    <ImageCard
      title="Image 4"
      text="This image will generate an incident after the agent has updated its signature database"
      url="/resources/images/test-images/image4.jpg"
    />
  </StyledRootContainer>
);

export default Cards;
