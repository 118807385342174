import { Typography, Box, styled } from "@mui/material";
import DeviceIcon from "@mui/icons-material/PhoneAndroid";

const Mobile = () => (
  <StyledRootContainer>
    <StyledTypography>Mobile support is not yet added.</StyledTypography>
    <StyledIconContainer>
      <StyledDeviceIcon />
    </StyledIconContainer>
  </StyledRootContainer>
);

const StyledRootContainer = styled(Box)({
  display: "flex",
  alignContent: "center",
  justifyContent: "center",
  flexFlow: "column",
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginTop: 50,
  width: "100%",
  textAlign: "center",
  fontSize: 20,
  color: theme.palette.secondary.main,
  fontWeight: "bold",
}));

const StyledIconContainer = styled(Box)({
  margin: "auto",
  marginTop: 20,
});

const StyledDeviceIcon = styled(DeviceIcon)(({ theme }) => ({
  display: "flex",
  alignContent: "center",
  justifyContent: "center",
  flexFlow: "column",
}));

export default Mobile;
