import { useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  StepContent,
  Typography,
  Stack,
  Box,
  styled,
} from "@mui/material";

function TutorialInstructions({ hideTutorial }: { hideTutorial: () => void }) {
  const [step, setStep] = useState(0);
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper orientation={"vertical"} activeStep={step}>
        <Step>
          <StepLabel>Verify latest software version</StepLabel>
          <StepContent>
            <div style={{ display: "flex", flexFlow: "column" }}>
              <StyledSection sx={{ paddingBottom: "10px" }}>
                {`In the ProActive Manager Console, go to Tools > Check for updates. If the agent tab for the agent type(s) that should be tested has a green check mark, the latest version of the agent software has been downloaded. The software version is stated as “Downloaded version”.`}
              </StyledSection>
              <StyledSection sx={{ paddingBottom: "10px" }}>
                {`Verify that the computer agent that should be tested has this version installed by comparing it with the agent version stated in the Management Console or the Web console. In the Management Console, open the information dialog by double-clicking on the agent in the list in the Agents section. In the Web Console, search for the agent name in the agent list in the Agent section. In both interfaces, check for “Agent version”. If the agent does not have the latest version, perform an update in the same manner as the installation process.`}
              </StyledSection>
              <StyledSection>
                {`For testing a cloud agent, make sure to install the latest version on the machine running the agent.`}
              </StyledSection>
            </div>
            <Stack sx={{ padding: "1vw 0" }} direction="row" spacing={2}>
              <Button variant="contained" onClick={() => setStep(step + 1)}>
                Next
              </Button>
            </Stack>
          </StepContent>
        </Step>
        <Step>
          <StepLabel>Download Signatures</StepLabel>
          <StepContent>
            <div style={{ display: "flex", flexFlow: "column" }}>
              <StyledSection sx={{ paddingBottom: "10px" }}>
                If the test will be carried out on a computer agent that just
                has been installed, please note that it may take up to 10
                minutes before it has downloaded the signature database.
              </StyledSection>
              <StyledSection>
                A green check mark (
                <img
                  style={{
                    width: "24px",
                    height: "auto",
                  }}
                  src="/resources/images/database-ok.png"
                  alt="Database ok"
                />
                ) in the Database column for the agent in the Agent section in
                the Management Console indicates that the agent has the latest
                signature database.
              </StyledSection>
            </div>
            <Stack sx={{ padding: "1vw 0" }} direction="row" spacing={2}>
              <Button variant="outlined" onClick={() => setStep(step - 1)}>
                Back
              </Button>
              <Button variant="contained" onClick={() => setStep(step + 1)}>
                Next
              </Button>
            </Stack>
          </StepContent>
        </Step>
        <Step>
          <StepLabel>Download and access demo images</StepLabel>
          <StepContent>
            <StyledSection sx={{ paddingBottom: "10px" }}>
              Download a demo image. The demo images provided here contain NO
              child sexual abuse material, but the system is designed to report
              them as an incident for testing purposes.
            </StyledSection>
            <StyledSection sx={{ paddingBottom: "10px" }}>
              After downloading, open the image in any program.
            </StyledSection>
            <Stack sx={{ padding: "1vw 0" }} direction="row" spacing={2}>
              <Button variant="outlined" onClick={() => setStep(step - 1)}>
                Back
              </Button>
              <Button
                variant="contained"
                href="/resources/images/test-images/image3.jpg"
                download
              >
                Download image
              </Button>
              <Button variant="contained" onClick={() => setStep(step + 1)}>
                I have downloaded and opened the image
              </Button>
            </Stack>
          </StepContent>
        </Step>
        <Step>
          <StepLabel>Verify incident</StepLabel>
          <StepContent>
            <StyledSection sx={{ paddingBottom: "10px" }}>
              An incident should now be visible in the "Incidents" view in the
              Management Console and in the Web Console.
            </StyledSection>
            <StyledSection>
              In some cases there might be a slight delay before any incident
              shows up in the system. Please wait 5 minutes before starting
              troubleshooting.
            </StyledSection>
            <Stack sx={{ padding: "1vw 0" }} direction="row" spacing={2}>
              <Button variant="outlined" onClick={() => setStep(step - 1)}>
                Back
              </Button>
              <Button variant="contained" onClick={() => hideTutorial()}>
                An incident is reported, close the tutorial
              </Button>
            </Stack>
          </StepContent>
        </Step>
      </Stepper>
    </Box>
  );
}

const StyledSection = styled(Typography)({
  letterSpacing: 0.2,
  maxWidth: "800px",
  fontFamily: "Montserrat Light",
  fontSize: "14px",
});

export default TutorialInstructions;
