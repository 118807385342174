import { Typography, Grid, Box, styled } from "@mui/material";
import ProActiveIcon from "./ProActiveIcon";
import ProActiveLogo from "./ProActiveLogo";

const Header = () => {
  return (
    <Grid
      item
      sx={{ alignItems: "center", justifyContent: "center" }}
      container
    >
      <Grid item xs={12} sm={12} md={4}>
        <Box sx={{ minWidth: "208px" }}>
          <ProActiveIcon />
          <ProActiveLogo />
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={4} sx={{ paddingBottom: "5px" }}>
        <StyledHeader>Demo images for NetClean ProActive</StyledHeader>
      </Grid>
      <Grid item xs={0} sm={0} md={4} />
    </Grid>
  );
};

const StyledHeader = styled(Typography)({
  fontFamily: "Montserrat Medium",
  fontSize: "18px",
  letterSpacing: 0.5,
  textAlign: "center",
  width: "100%",
});

export default Header;
