import React, { useState } from "react";
import Cards from "./Content/Cards";
import Instructions from "./Content/Instructions";
import TutorialInstructions from "./Content/TutorialInstructions";
import { Button, Typography, Box, styled, SxProps, Theme } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import CopyrightFooter from "./Footer/CopyrightFooter";
import { isBrowser } from "react-device-detect";
import Mobile from "./Content/Mobile";
import Header from "./Header/Header";
import { PALETTE_COLORS } from "./theme/ColorPalettes";

function App() {
  const [shouldShowTutorial, setShouldShowTutorial] = useState(false);
  const [shouldShowDemoImages, setShouldShowDemoImages] = useState(false);

  const getButtonSx: (isTutorialClicked?: boolean) => SxProps<Theme> = (
    isClicked
  ) =>
    isClicked
      ? {
          backgroundColor: `${PALETTE_COLORS.proActive}10`,
          padding: "3px 9px 3px 10px",
        }
      : { padding: "3px 15px" };

  if (isBrowser) {
    return (
      <StyledRootContainer>
        <StyledPageContainer>
          <Header />
          <StyledDivider />
          <StyledContentContainer>
            <Typography
              sx={{
                textAlign: "center",
                fontFamily: "Montserrat Medium",
                padding: "20px",
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              This site is used to test the functionality of your NetClean
              ProActive installation.
            </Typography>
            <Box sx={{ backgroundColor: PALETTE_COLORS.background }}>
              <Instructions />
            </Box>
            <StyledButtonContainer>
              <Box sx={{ margin: "1vw", textAlign: "center" }}>
                <Button
                  sx={getButtonSx(shouldShowTutorial)}
                  variant="outlined"
                  onClick={() => {
                    setTimeout(function () {
                      setShouldShowDemoImages(false);
                      setShouldShowTutorial(!shouldShowTutorial);
                    }, 200);
                  }}
                >
                  {shouldShowTutorial ? (
                    <>
                      <ExpandLess />
                      Hide tutorial
                    </>
                  ) : (
                    <>
                      <ExpandMore />
                      Show tutorial
                    </>
                  )}
                </Button>
              </Box>
              <Box sx={{ margin: "1vw", textAlign: "center" }}>
                <Button
                  sx={getButtonSx(shouldShowDemoImages)}
                  variant="outlined"
                  onClick={() => {
                    setTimeout(function () {
                      setShouldShowTutorial(false);
                      setShouldShowDemoImages(!shouldShowDemoImages);
                    }, 200);
                  }}
                >
                  {shouldShowDemoImages ? (
                    <>
                      <ExpandLess />
                      Hide individual demo images
                    </>
                  ) : (
                    <>
                      <ExpandMore />
                      Show individual demo images
                    </>
                  )}
                </Button>
              </Box>
              <Box
                sx={{
                  margin: "1vw 1vw 1vw 5vw",
                  textAlign: "center",
                }}
              >
                <Button
                  variant="contained"
                  href="/resources/images/test-images/test-images.zip"
                  download
                >
                  Download all demo images
                </Button>
              </Box>
            </StyledButtonContainer>
          </StyledContentContainer>
          {shouldShowTutorial ? (
            <Box sx={{ width: "100%" }}>
              <StyledDivider />
              <StyledHeader>Tutorial</StyledHeader>
              <TutorialInstructions
                hideTutorial={() => setShouldShowTutorial(false)}
              />
            </Box>
          ) : (
            ""
          )}
          <StyledDivider />
          {shouldShowDemoImages ? (
            <Box>
              <Cards />
            </Box>
          ) : (
            ""
          )}
        </StyledPageContainer>
        <CopyrightFooter />
      </StyledRootContainer>
    );
  } else {
    return (
      <div className="App">
        <Mobile />
      </div>
    );
  }
}

const StyledRootContainer = styled(Box)({
  paddingBottom: "40px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexFlow: "column",
});
const StyledPageContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexFlow: "column",
  maxWidth: "1280px",
  width: "100%",
  marginTop: "20px",
});

const StyledContentContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexFlow: "column",
  maxWidth: "1280px",
  width: "100%",
  marginTop: "20px",
});

const StyledDivider = styled(Box)({
  width: "100%",
  height: "4px",
  margin: "10px 0px",
  backgroundColor: "#f6f7fb",
});

const StyledButtonContainer = styled(Box)({
  display: "flex",
  flexFlow: "row",
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: "20px",
  marginBottom: "auto",
});

const StyledHeader = styled(Typography)({
  fontSize: "20px",
  fontFamily: "Montserrat",
  color: PALETTE_COLORS.fontBlack,
  marginBottom: "20px",
  textAlign: "left",
});

export default App;
