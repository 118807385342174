import { PALETTE_COLORS } from "./ColorPalettes";
import { createTheme, ThemeOptions } from "@mui/material/styles";

const themeObject: ThemeOptions = {
  palette: {
    primary: {
      main: PALETTE_COLORS.primary,
      contrastText: PALETTE_COLORS.white,
    },
    secondary: {
      main: PALETTE_COLORS.white,
      contrastText: PALETTE_COLORS.primary,
    },
    success: {
      main: PALETTE_COLORS.success,
    },
  },
  components: {
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: PALETTE_COLORS.lightGrey,
          "&.Mui-active": {
            color: PALETTE_COLORS.mediumGrey,
          },
          "&.Mui-completed": {
            color: PALETTE_COLORS.success,
          },
        },
        text: {
          fontFamily: "Montserrat Semi Bold",
          fill: PALETTE_COLORS.fontBlack,
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          lineHeight: "24px",
          fontSize: "16px",
          color: PALETTE_COLORS.darkGrey,
          fontFamily: "Montserrat Medium",
        },
        label: {
          "&.Mui-active, &.Mui-completed": {
            lineHeight: "24px",
            fontSize: "16px",
            fontFamily: "Montserrat Medium",
          },
          "&.Mui-disabled": {
            lineHeight: "24px",
            fontSize: "16px",
            fontFamily: "Montserrat Medium",
            color: PALETTE_COLORS.darkGrey,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Montserrat Medium",
          minHeight: "32px",
          fontSize: "14px",
          lineHeight: "16px",
          letterSpacing: 0.5,
          borderRadius: 0,
        },
      },
    },
  },
};

const theme = createTheme(themeObject);

export default theme;
