import { Divider, Grid, Typography, styled } from "@mui/material";

const Instructions = () => (
  <Grid container>
    <Grid sx={{ padding: "38px" }} item xs>
      <StyledSubheader>Prerequisites</StyledSubheader>
      <StyledListSection sx={{ paddingBottom: "10px" }} variant="body2">
        The ProActive Management Server needs to have downloaded the latest
        signatures.
      </StyledListSection>
      <StyledListSection sx={{ paddingBottom: "10px" }} variant="body2">
        The on-access scanning needs to be enabled (this feature is enabled by
        default).
      </StyledListSection>
      <StyledListSection variant="body2">
        The agent used in the test must have communicated with the ProActive
        Management Server at least once.
      </StyledListSection>
    </Grid>
    <Divider orientation="vertical" sx={{ margin: "38px 0px" }} flexItem />
    <Grid sx={{ padding: "38px" }} item xs>
      <StyledSubheader>Instructions</StyledSubheader>
      <StyledSection sx={{ paddingBottom: "10px" }} variant="body2">
        To test a computer agent you open a downloaded image in a program of
        your choice. You can also start a scan of the computer after you have
        downloaded the images.
      </StyledSection>
      <StyledSection sx={{ paddingBottom: "10px" }} variant="body2">
        To test a cloud agent you upload a downloaded image to a drive in the
        cloud storage that is monitored by the agent.
      </StyledSection>
      <StyledSection variant="body2">
        NetClean provide 4 harmless demo images that can be used for testing the
        system.
      </StyledSection>
    </Grid>
  </Grid>
);

const StyledSection = styled(Typography)({
  fontFamily: "Montserrat Light",
  fontSize: "14px",
  letterSpacing: "0.2",
});

const StyledListSection = styled(StyledSection)({
  display: "list-item",
  marginLeft: "18px",
});

const StyledSubheader = styled(Typography)({
  paddingBottom: "23px",
  fontFamily: "Montserrat Medium",
  fontSize: "14px",
  letterSpacing: "0.2",
});

export default Instructions;
