import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  IconButton,
  styled,
  Box,
} from "@mui/material";
import React from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { PALETTE_COLORS } from "../theme/ColorPalettes";

const StyledContent = styled(CardContent)({
  display: "flex",
  flexFlow: "row",
  alignItems: "center",
});

const StyledOverlay = styled(Box)({
  height: "200px",
  marginTop: "-200px",
  display: "flex",
  flexFlow: "column",
  textAlign: "center",
  justifyContent: "center",
});

const StyledInfoTextContainer = styled(Box)({
  width: "80%",
  marginRight: "4px",
  minHeight: "120px",
  display: "flex",
  flexFlow: "column",
  textAlign: "left",
});

const StyledDownloadIconButton = styled(IconButton)(({ theme }) => ({
  background: theme.palette.secondary.main,
  marginLeft: "auto",
  borderRadius: "100%",
  width: "30px",
  height: "30px",
  fill: "white",
}));

const StyledAnchor = styled("a")({
  marginLeft: "20px",
});

const ImageCard = ({
  url,
  title,
  text,
}: {
  url: string;
  title: string;
  text: string;
}) => (
  <Box
    sx={{
      padding: "8px",
      width: "100%",
    }}
  >
    <Card
      sx={{
        width: "100%",
      }}
    >
      <CardMedia
        sx={{
          height: "200px",
          filter: "opacity(40%)",
        }}
        image={url}
        title={title}
      />
      <StyledOverlay>
        <Typography
          sx={{
            color: PALETTE_COLORS.primary,
            zIndex: 2,
            fontSize: "36px",
            fontFamily: "Montserrat",
          }}
        >
          DEMO
        </Typography>
      </StyledOverlay>
      <StyledContent>
        <StyledInfoTextContainer>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Montserrat",
              fontWeight: "bold",
              color: PALETTE_COLORS.fontBlack,
            }}
            gutterBottom
          >
            {title}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Montserrat Light",
              fontWeight: "300",
              color: PALETTE_COLORS.fontBlack,
            }}
            gutterBottom
          >
            {text}
          </Typography>
        </StyledInfoTextContainer>
        <StyledAnchor href={url} download>
          <StyledDownloadIconButton>
            <DownloadIcon />
          </StyledDownloadIconButton>
        </StyledAnchor>
      </StyledContent>
    </Card>
  </Box>
);

export default ImageCard;
