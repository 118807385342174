import { styled } from "@mui/system";
import * as React from "react";

const NetCleanLogo = () => {
  return (
    <StyledSvg
      version="1.1"
      id="Lager_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 375.2 129"
    >
      <g>
        <circle fill="#0A2240" cx="362.7" cy="85.4" r="7.3"></circle>
        <path
          fill="#0A2240"
          d="M120.7,78.9V65.2H116V52.4h5v-10h14.9v10h9.6v12.8h-9.6v10.1c0,3,2.4,4.1,5,4.1c1.5,0,3.3-0.4,5.1-1.2v12.8
		c-2.6,1.4-6.6,2.3-10.6,2.3C125.6,93.2,120.7,88.7,120.7,78.9"
        ></path>
        <polyline
          fill="#0A2240"
          points="202.7,38.6 219.3,38.6 219.3,92 202.7,92 202.7,38.6 	"
        ></polyline>
        <path
          fill="#0A2240"
          d="M311.1,53.5h16v5.4c2.5-3.2,6.4-6.3,11.8-6.3c8.1,0,13.2,5.4,13.2,14.1V92h-16V71.8c0-3.3-2-5.2-4.4-5.2
		c-2.4,0-4.6,1.9-4.6,5.2V92h-16V53.5"
        ></path>
        <g>
          <g>
            <path
              fill="#0A2240"
              d="M52.3,38.9c0,0-0.2,24.2-0.5,28.1c-0.1,5.6-4.2,10.1-9.6,11l9.8,14.4h17.8V38.9L52.3,38.9z"
            ></path>
            <g>
              <path
                fill="#0A2240"
                d="M40.5,56L28.8,38.9H11.5v53.5h17.4c0-0.6,0.2-24.6,0.3-25.1c0,0,0,0,0,0C29.2,61.1,34.3,56.1,40.5,56z"
              ></path>
            </g>
          </g>
          <circle fill="#0A2240" cx="40.3" cy="67.2" r="7.3"></circle>
        </g>
        <path
          fill="#0A2240"
          d="M114.3,73.3c0-12.2-6.6-22-20.8-22c-12,0-20.6,9.1-20.6,21v0.1c0,10.2,5.9,17.5,15.2,19.9
		c2.1,0.5,4.4,0.8,6.8,0.8c7.1,0,12.1-2.4,15.9-6.1l-6.6-9.7h-0.1c-2.6,2.7-5.1,4-8.1,4c-3.1,0-5.6-1.4-7-4
		c-0.2-0.4-0.4-0.8-0.6-1.3h25.7C114.3,75.2,114.3,74.2,114.3,73.3z M88.3,68.8c0.5-3.7,2.5-6,5.3-6c2.9,0,5.2,2.2,5.6,6H88.3z"
        ></path>
        <path
          fill="#0A2240"
          d="M302,56.6c-3.5-3.5-8.8-5.2-16.9-5.2c-7,0-12.2,1.2-16.8,2.9l0.1,0.5l6.4,9.4c2.3-0.7,4.8-1.1,7.5-1.1
		c5.1,0,7.4,2.1,7.4,5.8v0.7c-1.8-0.7-6.1-1.5-9.4-1.5c-7.4,0-12.7,2.8-14.5,8c-0.5,1.3-0.7,2.8-0.7,4.4v0.1c0,1.4,0.2,2.6,0.5,3.8
		c1.5,5.5,6.3,8.5,12.6,8.5c5,0,8.7-2,11.5-5v4h16.6V69.3C306.2,63.5,305,59.6,302,56.6z M289.8,77.3c0,3.8-2.2,6.2-5.1,6.2
		c-2.1,0-3.8-1.5-3.8-3.9v-0.1c0-2.5,1.8-4.5,5.1-4.5c1.4,0,2.9,0.3,3.8,0.7V77.3z"
        ></path>
        <path
          fill="#0A2240"
          d="M264,73.3c0-12.2-6.6-22-20.8-22c-12,0-20.6,9.1-20.6,21v0.1c0,10.2,5.9,17.5,15.2,19.9
		c2.1,0.5,4.4,0.8,6.8,0.8c7.1,0,12.1-2.4,15.9-6.1l-6.6-9.7h-0.1c-2.6,2.7-5.1,4-8.1,4c-3.1,0-5.6-1.4-7-4
		c-0.2-0.4-0.4-0.8-0.6-1.3h25.7C264,75.2,264,74.2,264,73.3z M238,68.8c0.5-3.7,2.5-6,5.3-6c2.9,0,5.2,2.2,5.6,6H238z"
        ></path>
        <g>
          <path
            fill="#0A2240"
            d="M186.4,72c-1.9,3.8-5.8,6.3-10.3,6.3c-6.4,0-11.6-5.2-11.6-11.6c0-6.4,5.2-11.6,11.6-11.6
			c4.4,0,8.2,2.4,10.1,6l13.3-7.8c-4.3-7.8-11.9-13.3-23.2-13.3c-15.3,0-27.2,11.4-27.2,26.7v0.1c0,15.9,12.4,26.6,26.6,26.6
			c12.7,0,19.9-6.2,24.2-13.8L186.4,72z"
          ></path>
        </g>
      </g>
    </StyledSvg>
  );
};

const StyledSvg = styled("svg")({
  height: 48,
  margin: "-20px 10px",
});

export default NetCleanLogo;
